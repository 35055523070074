import "./App.css";
import { IntlProvider } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./ui-components/PrivateRoute";
import Dashboard from "./ui-components/Dashboard";
import Login from "./ui-components/pages/Login";
import OAuth2 from "./ui-components/pages/OAuth2";
import Registration from "./ui-components/pages/Registration";
import Notifications from "./ui-components/Notifications";
import menu from "./ui-components/AppNavigation/menu";
import Logout from "./ui-components/pages/Logout";
import NotFound from "./ui-components/pages/NotFound";
import ErrorBoundary from "./ui-components/ErrorBoundary";
import locale from "./locale";
import publicRoutes from "./routes/public";
import usePageViews from "@hooks/usePageViews";

import { NavigationProvider } from "@services/NavigationContext";
import { ApiAuthProvider } from "@services/ApiRequestContext";
import { DataProvider } from "@services/DataContext";
import AuthenticationService from "@services/AuthenticationService";

function App() {
  usePageViews();

  /* !STANDARD, defualt route logic depending on permissions
    Admins and managers have access to home even if empty,
    thus avoiding to break default tests */
    const userScopes = AuthenticationService.getUserScopes(); 
    let customDefaultRoute;
  
    if(!userScopes.includes("home:read")) {
      if(userScopes.includes("customers_all:read")) {
        customDefaultRoute = "/customers";
      } else if(userScopes.includes("customers_crui:read")) {
        customDefaultRoute = "/orders";
      } else if(userScopes.includes("customers_self_only:read")) {
        customDefaultRoute = "/export";
      }
    }

  return (
    <NavigationProvider>
      <ErrorBoundary>
        <IntlProvider
          messages={locale[localStorage.getItem("lang")]}
          locale={localStorage.getItem("lang")}
          defaultLocale="it"
        >
          <Notifications>
            <ApiAuthProvider>              
              <Switch>
                <PrivateRoute
                  exact
                  path="/"
                  component={() => <Redirect to={{ pathname: "/home" }} />}
                />
                {
                      /* !STANDARD, defualt route logic depending on permissions */
                      Boolean(customDefaultRoute) && (
                        <PrivateRoute
                          exact
                          path="/home"
                          component={() => (
                            <Redirect to={{ pathname: customDefaultRoute }} />
                          )}
                        />
                      )
                    }
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/oauth2" component={OAuth2} />
                <Route
                  exact
                  path="/registration/:token"
                  component={Registration}
                />

                {publicRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.href}
                    component={route.component}
                  />
                ))}

                <DataProvider>
                  <PrivateRoute
                    path={[
                      ...menu
                        .reduce(
                          (acc, route) => [
                            ...acc,
                            ...(route.dropdown ? route.dropdown : [route]),
                          ],
                          []
                        )
                        .map((route) => route.href),
                      "/profile",
                    ]}
                    component={Dashboard}
                  />
                </DataProvider>
                <Route path="*" component={NotFound} />
              </Switch>              
            </ApiAuthProvider>
          </Notifications>
        </IntlProvider>
      </ErrorBoundary>
    </NavigationProvider>
  );
}

export default App;
