import React from "react";
import {
  ArchiveBoxArrowDownIcon,
  ArrowTrendingUpIcon,
  BriefcaseIcon,
  HomeIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";

const Home = React.lazy(() =>
  import(/* webpackChunkName: "home" */ "@pages/Home")
);

const Customers = React.lazy(() =>
  import(/* webpackChunkName: "customers" */ "@pages/Customers")
);

const Export = React.lazy(() =>
  import(/* webpackChunkName: "export" */ "@pages/Export")
);

const Orders = React.lazy(() =>
  import(/* webpackChunkName: "orders" */ "@pages/Orders")
);

const TrendingLines = React.lazy(() =>
  import(/* webpackChunkName: "treinding_lines" */ "@pages/TrendingLines")
);

const routes = [
  {
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home,
    hidden: false,
    scopes: ["home:read"], /* !STANDARD, visibile only to admins/manager, for tests */
  },
  {
    name: "customers",
    href: "/customers",
    Icon: BriefcaseIcon,
    component: Customers,
    hidden: false,
    scopes: ["customers_all:read"]
  },
  {
    name: "crui_orders",
    href: "/orders",
    Icon: ShoppingBagIcon,
    component: Orders,
    hidden: false,
    scopes: ["customers_crui:read"],
  },
  {
    name: "trending_lines",
    href: "/trending_lines",
    Icon: ArrowTrendingUpIcon,
    component: TrendingLines,
    hidden: false,
    scopes: ["customers_all:read"],
  },
  {
    name: "export",
    href: "/export",
    Icon: ArchiveBoxArrowDownIcon,
    component: Export,
    hidden: false,
    scopes: ["customers_self_only:read"],
  },
];

export default routes;
