import React from "react";
import SelectRole from "./SelectRole";
import SelectCustomer from "../../../features/Customers/inputs/SelectCustomer"; /* !STANDARD */
import { CustomerCompactInfo } from "@features/Customers/components";
import { isEmailValid } from "@utils/validations";

export const getColumns = (intl) => [
  {
    title: "E-mail",
    field: "email",
    defaultSort: "asc", /* !STANDARD */
    validate: rowData => {      
      return isEmailValid(rowData.email) || intl.formatMessage({ id: "enter_email" });
    },
  },
  { /* ! STANDARD */
    title: intl.formatMessage({ id: "username" }),
    field: "username",
    render: ({ username }) => username ? <b>{username}</b> : '-',
  },
  {
    title: intl.formatMessage({ id: "name" }),
    field: "full_name",
    render: ({ full_name }) => <b>{full_name}</b>,
  },
  {
    title: intl.formatMessage({ id: "role" }),
    field: "role_id",
    customFilterAndSearch: (rolename, rowData) => rowData.role.name.startsWith(rolename),
    render: ({ role }) => <b className="capitalize">{role.name}</b>,
    editComponent: (props) => <SelectRole {...props} />,
    cellStyle: {
      minWidth: 200,
    },
  },
  {
    /* ! STANDARD */ title: intl.formatMessage({ id: "customer" }),
    field: "customer_id",
    cellStyle: {
      minWidth: 400,
    },
    render: ({ customer, customer_id }) =>
      customer_id ? (
        <CustomerCompactInfo
          codOrder={customer?.cod_order}
          desDescription={customer?.des_description}
          desRegistryName={customer?.des_registry_name}          
        />
      ) : (
        "-"
      ),
    customFilterAndSearch: (term, rowData) => {
      const lowerCaseTerm = term.toLowerCase();
      return (
        rowData.customer?.cod_order.toLowerCase().includes(lowerCaseTerm) ||
        rowData.customer?.des_description?.toLowerCase().includes(lowerCaseTerm) ||
        rowData.customer?.des_registry_name?.toLowerCase().includes(lowerCaseTerm)
      );
    },
    editComponent: (props) => <SelectCustomer {...props} />,
  },
  {
    title: intl.formatMessage({ id: "disabled" }),
    field: "disabled",
    type: "boolean",
  },
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

export const getLocalization = (intl) => ({
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: intl.formatMessage({ id: "delete_user" }),
    },
  },
  pagination: {
    labelDisplayedRows: intl.formatMessage({ id: "users_displayed_rows" }),
    labelRowsSelect: intl.formatMessage({ id: "users"}),
  },
});
