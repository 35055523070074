import { DocumentChartBarIcon, UsersIcon } from "@heroicons/react/24/outline"; // !STANDARD, TicketIcon TICKET NOT USED IN THIS APP
import routes from "../../routes";
import Roles from "../pages/Roles";
import Users from "../pages/Users";
// import Support from "../../ui-components/pages/Support"; !STANDARD, TICKET NOT USED IN THIS APP
import AuditFrontend from "../pages/AuditFrontend";
import AuditBackend from "../pages/AuditBackend";

const menu = [
  ...routes,
  {
    name: "users_settings",
    Icon: UsersIcon,
    dropdown: [
      {
        scopes: ["user:read"],
        href: "/users",
        name: "users",
        component: Users,
      },
      {
        scopes: ["role:read"],
        href: "/roles",
        name: "roles",
        component: Roles,
      },
    ],
  },
  {
    name: "audit",
    Icon: DocumentChartBarIcon,
    dropdown: [
      {
        scopes: ["audit:read"],
        href: "/audit/frontend",
        name: "frontend",
        component: AuditFrontend,
      },
      {
        scopes: ["audit:read"],
        href: "/audit/backend",
        name: "backend",
        component: AuditBackend,
      },
    ],
  },
  /* { !STANDARD, TICKET NOT USED IN THIS APP
    name: "support",
    href: "/support",
    Icon: TicketIcon,
    component: Support,
  }, */
];

export default menu;
