const it_custom = {
  associated_users_info_msg: "Per associare un'utenza ad un cliente, vai alla pagina di gestione utenze e modifica l'utenza.",
  associated_users: "Utenze associate",
  crui_orders: "Ordini CRUI",
  crui_orders_table_title: "Elenco ordini CRUI",
  customer_none: "Nessun cliente",
  customer: "Cliente",
  customers_table_title: "Elenco clienti",
  customers: "Clienti",
  delete_row_prompt: "Vuoi davvero eliminare questo elemento?",
  description: "Descrizione",
  displayed_records: "{from}-{to} di {count} risultati",
  empty_table: "Nessun risultato da mostrare.",
  end_year_month: "Anno e mese di fine",
  enter_username: "Inserisci lo username",
  export_data: "Esporta dati",
  export_excel: "Esporta Excel",
  export_pdf: "Esporta PDF",
  export: "Esportazione",
  exported_successfully: "Esportazione eseguita con successo",
  generic_error: "Errore imprevisto. Se il problema persiste, contatta l'assistenza.",
  item_created_successfully: "Elemento creato con successo",
  item_deleted_successfully: "Elemento eliminato con successo",
  item_updated_successfully: "Elemento aggiornato con successo",
  month_year: "Mese / Anno",
  no_associated_data_to_export: "Nessun dato associato da esportare.",
  no_behaviour_kpi_data_found: "Nessun KPI disponibile per il periodo selezionato",
  no_data_to_show: "Nessun dato da mostrare",
  order_code: "Codice ordine",
  order_customer_associated_to_user: "Ordine/cliente associati all'utenza",
  order: "Ordine",
  page_customers: "Pagina clienti",
  page_export_own_data: "Pagina esportazione propri dati",
  page_orders: "Pagina ordini",
  page_trending_lines: "Pagina serie storiche",
  page_visible_only_to_admins: "Questa pagina è visibile solo agli amministratori, a scopo di test",
  pdf_export_range_greater_than_12_months: "L'intervallo di esportazione PDF non può essere maggiore di 12 mesi. Seleziona un intervallo più piccolo o un diverso formato di esportazione.",
  product: "Prodotto",
  quantity: "Quantità",
  records: "risultati",
  registry: "Anagrafica",
  select_kpis: "Seleziona i KPI",
  select_order_and_product: "Seleziona un ordine ed un prodotto per visualizzare le serie associate",
  select_products: "Seleziona i prodotti",
  start_year_month: "Anno e mese di inizio",
  trending_line: "Serie storica",
  trending_lines: "Serie storiche",
  user_add_notice: "Per aggiungere utenti di ruolo diverso da utente semplice (user), contattare l'assistenza",
  username_already_in_use: "Username già in uso",
  username: "Username",
};

export default it_custom;