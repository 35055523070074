import React from 'react';

interface Props {
  codOrder: string;
  desRegistryName: string;
  desDescription: string;

}

/* shows the customer info on multiple rows, tipically used in table cells */

export const CustomerCompactInfo: React.FC<Props> = ({
   codOrder,
   desRegistryName,
   desDescription
}) => {
  return (
    <div className="text-sm">
      {codOrder && <div><b>{codOrder}</b></div>}
      {codOrder && <div><b>{desDescription}</b></div>}
      {codOrder && <div>{desRegistryName}</div>}
    </div>
  );
};