const en_custom = {
  associated_users_info_msg: "To associate a user to a customer, go to the users management page and edit the user.",
  associated_users: "Associated users",
  crui_orders: "CRUI Orders",
  crui_orders_table_title: "CRUI orders list",
  customer_none: "No customer",
  customer: "Customer",
  customers_table_title: "Customers list",
  customers: "Customers",
  delete_row_prompt: "Do you really want to delete this item?",
  description: "Description",
  displayed_records: "{from}-{to} of {count} items",
  empty_table: "No items to show.",
  end_year_month: "End year and month",
  enter_username: "Enter username",
  export_data: "Export data",
  export_excel: "Export Excel",
  export_pdf: "Export PDF",
  export: "Export",
  exported_successfully: "Exported successfully",
  generic_error: "Unexpected error. IF the problem persists, contact the support.",
  item_created_successfully:  "Item created successfully",
  item_deleted_successfully: "Item deleted successfully",
  item_updated_successfully: "Item updated successfully",
  month_year: "Month / Year",
  no_associated_data_to_export: "No data associated data to export.",
  no_behaviour_kpi_data_found: "No KPI available for the selected period",
  no_data_to_show: "No data to show",
  order_code: "Order code",
  order_customer_associated_to_user: "Order/customer associated to this user",
  order: "Order",
  page_customers: "Customers page",
  page_export_own_data: "Page to export your own data",
  page_orders: "Orders page",
  page_trending_lines: "Trending lines page",
  page_visible_only_to_admins: "This page is visible only to admins, for testing purposes",
  pdf_export_range_greater_than_12_months: "The PDF export range cannot be greater than 12 months. Select a smaller range or a different export format.",
  product: "Product",
  quantity: "Quanity",
  records: "items",
  registry: "Registry",
  select_kpis: "Select KPIs",
  select_order_and_product: "Select an order and a product to display the associated series",
  select_products: "Select products",
  start_year_month: "Start year and month",
  trending_line: "Trending Line",
  trending_lines: "Trending Lines",
  user_add_notice: "To add users with a role other than simple 'user', contact support",
  username_already_in_use: "Username already in use",
  username: "Username",
};

export default en_custom;