import React, { useContext } from "react";
import SearchSelect from "@ui-components/SearchSelect";
import { DataContext } from "@services/DataContext";
import { useIntl } from "react-intl";
import _ from "lodash";

const SelectCustomer = ({ value, onChange, ...rest }) => {
  const { customers } = useContext(DataContext);
  const intl = useIntl();

  const handleOnChange = (newValue) => {
    onChange(newValue.value || null);
  };

  const options = [
    {
      value: null,
      label: "- " + intl.formatMessage({ id: "customer_none" }) + " -",
    },
    ..._.sortBy(customers, "cod_order").map((c) => ({
      value: c.id,
      label: [
        c.cod_order, c.des_description, c.des_registry_name
      ].filter(Boolean).join(' - '), // conditionally concatenate in the label only fields that are not empty
    })),
  ];

  const selectedValue = options.find((o) => o.value === value);

  return (
    <SearchSelect
      options={options}
      value={selectedValue}
      onChange={handleOnChange}
      isOptionSelected={(o) => o.value === selectedValue.value}
      {...rest}
    />
  );
};

export default SelectCustomer;
