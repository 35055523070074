import React, {useContext, useState} from "react";
import { useIntl } from "react-intl";
// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import { getColumns, OPTIONS, getLocalization } from "./Params";
import { tableIcons } from "../../TableIcons";
import AuthenticationService from "../../../services/AuthenticationService";
import { api } from "@services/apiRequest";
import useRoles from "./hooks/useRoles";
import TableStyle from "../../TableStyle";
import { DataContext } from "@services/DataContext";

function UsersTable({ users, setUsers, push }) {

  const intl = useIntl();
  const [columns] = useState(getColumns(intl))
  const [localization] = useState(getLocalization(intl))

  const scopes = AuthenticationService.getUserScopes();
  const roles = useRoles();

  /* !STANDARD */
  let isDeletable = () => true;
  let isEditable = () => true;
  const userCanEditRoles = scopes.includes("role:update");
  if(!userCanEditRoles && roles){
    columns.find(c => c.field === "role_id").editable = {
      onRowUpdate: false,
    }
    // only simple users can be deleted or edited by roles without role:update permission
    isDeletable = rowData => rowData.role.name === "user";
    isEditable = rowData => rowData.role.name === "user";
  };

  const { customers } = useContext(DataContext); /* !STANDARD */

  function manageUpdatedUser(oldData, newData) {
    const { [oldData.id]: tmp, ...rest } = users;
    let updatedUser =
      newData.role_id !== newData.role.id
        ? {
            ...newData,
            role: roles.find((r) => r.id === parseInt(newData.role_id)),
            /* !STANDARD update also customer data */
            customer: newData.customer_id ? customers.find((c) => c.id === parseInt(newData.customer_id)) : null 
          }
        : newData;

    setUsers({ ...rest, [newData.id]: updatedUser });
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      /* !STANDARD, if the edited username is an empty string, convert it to null  */
      if(!newData.username){
        newData.username = null;
      } else {
        newData.username = newData.username.trim().toLowerCase();
      }

      if(newData.email){
        newData.email = newData.email.trim().toLowerCase();
      }

      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      if (isEqual) {
        resolve();
      } else {
        api
          .post(`/users/${oldData.id}`, newData)
          .then(() => {
            manageUpdatedUser(oldData, newData);
            push({
              title: intl.formatMessage({
                id: "user_updated_successfully",
              }),
              type: "success",
            });
            resolve();
          })
          .catch((error) => {
            if(error?.response?.data?.detail === "email_already_in_use"){
              push({
                title: intl.formatMessage({
                  id: "email_already_in_use",
                }),
                type: "error",
              });
            /* !STANDARD */
            } else if(error?.response?.data?.detail === "username_already_in_use"){
              push({
                title: intl.formatMessage({
                  id: "username_already_in_use",
                }),
                type: "error",
              });
            }
            else {
              push({
                title: intl.formatMessage({
                  id: "server_error",
                }),
                type: "error",
              });
            }
            reject();
          });
        }
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      api
        .delete(`/users/${encodeURIComponent(newData.id)}`)
        .then(() => {
          const { [newData.id]: tmp, ...rest } = users;
          setUsers({ ...rest });
          push({
            title: intl.formatMessage({
              id: "user_deleted_successfully",
            }),
            type: "success",
          });
          resolve();
        })
        .catch((err) => {
          console.error("Error during user delete!", err);
          push({
            title: intl.formatMessage({
              id: "server_error",
            }),
            type: "error",
          });
          reject();
        });
    });

  return (
    <TableStyle>
      <MaterialTable
        columns={columns}
        data={users ? Object.values(users) : []}
        icons={tableIcons}
        isLoading={users === undefined}
        options={OPTIONS}
        localization={localization}
        editable={{
          ...(scopes.indexOf("user:update") >= 0
            ? { onRowUpdate: _onRowUpdate }
            : {}),
          ...(scopes.indexOf("user:delete") >= 0
            ? { onRowDelete: _onRowDelete }
            : {}),
          isDeletable: isDeletable, /* !STANDARD */
          isEditable: isEditable, /* !STANDARD */
        }}
      />
    </TableStyle>
  );
}

export default React.memo(UsersTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.users, nextProps.users);
});
