import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createContext } from "react";
import { DataContextSchema } from "./types";
import { api } from "@services/apiRequest";
import { CustomerSchema } from "@features/Customers/types";
import { useIntl } from "react-intl";
import { NotificationsContext } from "@ui-components/Notifications";
import { useAsyncError } from "@hooks/useAsyncError";
import AuthenticationService from "../../services/AuthenticationService";
import _ from 'lodash';

const dataContexDefaultValues:DataContextSchema = {
  customers: [],
  loggedUserAssociatedCustomer: null,
  setCustomers: () => {},
  updateCustomer: () => new Promise(() => {}),
  fetchCustomers: () => {},
  fetchingCustomers: false,
};

export const DataContext = createContext<DataContextSchema>(dataContexDefaultValues);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const intl = useIntl();
  const { push } = useContext(NotificationsContext);
  const throwError = useAsyncError();
  const userData = AuthenticationService.getUserData() || {};

  /* -------------------------------------------- */
  /*              GETTERS AND SETTERS             */
  /* -------------------------------------------- */

  const [customers, setCustomers] = useState<DataContextSchema["customers"]>(
    dataContexDefaultValues.customers
  );
  const [loggedUserAssociatedCustomer, setLoggedUserAssociatedCustomer] =
    useState<CustomerSchema | null>(dataContexDefaultValues.loggedUserAssociatedCustomer);
  const [fetchingCustomers, setFetchingCustomers] = useState<boolean>(dataContexDefaultValues.fetchingCustomers);

  /* -------------------------------------------- */
  /*                    ACTIONS                   */
  /* -------------------------------------------- */

  const fetchCustomers = useCallback(() => {
    setFetchingCustomers(true);

    api
      .get<CustomerSchema[]>(`customer/all`)
      .then((response) => {
        let customersData = _.sortBy(response.data, ['cod_order']);
        
        setCustomers(customersData);

        // set logged user associated customer
        const loggedUserAssociatedCustomerTemp = customersData.find(
          (customer) =>
            customer.users.some((user) => user.email === userData.email)
        );
        setLoggedUserAssociatedCustomer(
          loggedUserAssociatedCustomerTemp || null
        );
      })
      .catch((e) => {
        push({
          type: "error",
          title: intl.formatMessage({ id: "server_error" }),
        });
        throwError(e);
      })
      .finally(() => {
        setFetchingCustomers(false);
      });
  }, [intl, push, throwError, userData.email]);

  const updateCustomer = useCallback(
    (newData: CustomerSchema, oldData: CustomerSchema | undefined) =>
      new Promise((resolve, reject) => {
        // check if row content is equal - if so, don't do anything
        const isEqual = !oldData
          ? false
          : Object.keys(newData).every((k) => {
              const key = k as keyof CustomerSchema;
              return oldData[key] === newData[key];
            });

        if (isEqual) {
          resolve(newData);
        } else {
          try {
            api.put("customer", newData);
            push({
              type: "success",
              title: intl.formatMessage({ id: "item_updated_successfully" }),
            });
            // update the customers list state
            setCustomers((prevCustomers) => {
              const newCustomers = [...prevCustomers];
              const index = newCustomers.findIndex(
                (customer) => customer.id === newData.id
              );
              newCustomers[index] = newData;
              return newCustomers;
            });
            resolve(newData);
          } catch (e) {
            push({
              type: "error",
              title: intl.formatMessage({ id: "generic_error" }),
              text: "",
            });
            reject();
            throwError(e);
          }
        }
      }),
    [intl, push, throwError]
  );

  // initial fetch of customers data at app load
  useEffect(() => {
    fetchCustomers();
  }, [intl, push, throwError, fetchCustomers]);

  // prevents unnecessary object recreation during such non-provider-triggered renders
  const value = useMemo(
    (): DataContextSchema => ({
      customers,
      loggedUserAssociatedCustomer,
      setCustomers,
      updateCustomer,
      fetchCustomers,
      fetchingCustomers,
    }),
    [
      customers,
      loggedUserAssociatedCustomer,
      setCustomers,
      updateCustomer,
      fetchCustomers,
      fetchingCustomers,
    ]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
